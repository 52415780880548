/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle  */
import React, { useContext, useState } from 'react';
import {
  FormControl,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
  Grid,
  useMediaQuery,
  Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Help } from '@mui/icons-material';
import Kad from './Kad';
import { MultiSelectAutocomplete } from './MultiSelectAutocomplete';
import { filterOptions } from '../../pages/index';
import CalendarLocale from './CalendarLocale';
import Places from './Places';
import { MyContext } from '../../pages/_app';
import convert from '../../lib/dateStringConverter';

export default function CompanycontextsGroup({ value }) {
  const phone = useMediaQuery('(min-width:600px)');
  const context = useContext(filterOptions);
  const { languageFlag, setLanguageFlag, language } = useContext(MyContext);
  const { data, setData, newLangLoading } = context;
  const { selectedFilterStringCompany, search } = value ?? [];
  const [shouldKadUpdate, setShouldKadUpdate] = useState(false);
  const [shouldPlaceUpdate, setShouldPlaceUpdate] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [TKerror, setTKerror] = useState(false);
  const { t } = useTranslation();
  const [dateFrom, setDateFrom] = useState({
    recommendationDateFrom: null,
    closingDateFrom: null,
    alterationDateFrom: null,
  });
  const [dateTo, setDateTo] = useState({
    recommendationDateTo: null,
    closingDateTo: null,
    alterationDateTo: null,
  });
  const handleDateChangeFrom = (date, name) => {
    setDateFrom({ ...dateFrom, [name]: date });
  };
  const handleDateChangeTo = (date, name) => {
    setDateTo({ ...dateTo, [name]: date });
  };
  const [clear, setClear] = useState(false);

  function clearCompanyFilters() {
    setData({
      ...data,
      city: null,
      postcode: null,
      legalType: [],
      status: [],
      suspension: [],
      category: [],
      specialCharacteristics: [],
      armodiaGEMI: [],
      kad: [],
      places: [],
      recommendationDateFrom: null,
      recommendationDateTo: null,
      closingDateFrom: null,
      closingDateTo: null,
      alterationDateFrom: null,
      alterationDateTo: null,
    });
    setDateFrom({
      ...dateFrom,
      recommendationDateFrom: null,
      closingDateFrom: null,
      alterationDateFrom: null,
    });
    setDateTo({
      ...dateTo,
      recommendationDateTo: null,
      closingDateTo: null,
      alterationDateTo: null,
    });
    setClear(true);
  }
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography style={{
        display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', gap: '5px',
      }}
      >
        {selectedFilterStringCompany?.length !== 0 ? (
          <>
            {t('Προστέθηκε φίλτρο')}
            :
            {' '}
            {selectedFilterStringCompany?.map((item) => (
              <Chip label={item} key={item} />
            ))}
          </>
        ) : null}
      </Typography>
      <Button
        onClick={() => clearCompanyFilters()}
        style={{ alignSelf: 'flex-end' }}
      >
        {t('Καθαρισμος')}
      </Button>
      <Box
        display="flex"
        flexWrap={phone ? 'nowrap' : 'wrap'}
        justifyContent="space-between"
        marginBottom={phone ? '15px' : ''}
      >
        <MultiSelectAutocomplete
          context={data}
          setContext={setData}
          fullWidth
          name="legalType"
          options={newLangLoading ? [] : data.legalTypeData}
          multiple
          label={t('Νομική Μορφή')}
          loading={newLangLoading}
        />
        <MultiSelectAutocomplete
          context={data}
          setContext={setData}
          fullWidth
          name="status"
          options={newLangLoading ? [] : data.statusData}
          multiple
          label={t('Κατάσταση')}
          loading={newLangLoading}
        />
        <MultiSelectAutocomplete
          context={data}
          setContext={setData}
          fullWidth
          name="suspension"
          options={newLangLoading ? [] : data.suspensionData}
          multiple
          label={t('Σε Αναστολή Καταχώρησης')}
          loading={newLangLoading}
        />
      </Box>

      <Box
        display="flex"
        width="100%"
        flexWrap={phone ? 'nowrap' : 'wrap'}
        justifyContent="space-between"
        marginBottom={phone ? '15px' : ''}
      >
        <MultiSelectAutocomplete
          context={data}
          setContext={setData}
          fullWidth
          name="specialCharacteristics"
          options={newLangLoading ? [] : data.specialCharacteristicsData}
          multiple
          label={t('Ειδικοί Χαρακτηρισμοί')}
          loading={newLangLoading}
        />
        <MultiSelectAutocomplete
          context={data}
          setContext={setData}
          fullWidth
          name="armodiaGEMI"
          options={newLangLoading ? [] : data.armodiaGEMIData}
          multiple
          label={t('Αρμόδια Τοπική Υπηρεσία Γ.Ε.ΜΗ.')}
          loading={newLangLoading}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="left"
        flexWrap={phone ? 'nowrap' : 'wrap'}
        marginLeft="9px"
        marginTop={!phone ? '5px' : ''}
      >
        <Kad
          mode="kad"
          shouldKadUpdate={shouldKadUpdate}
          setShouldKadUpdate={setShouldKadUpdate}
          context={data}
          setContext={setData}
          data={data.kadData}
          clear={clear}
          setClear={setClear}
          name="kad"
          languageFlag={languageFlag}
          setLanguageFlag={setLanguageFlag}
          placeholder={t('Δραστηριότητα')}
          currentLanguage={language}
        />
        {phone && (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <IconButton
            disableFocusRipple
            edge="start"
            aria-label="Κ.Α.Δ Κωδικοί"
            href="https://www.aade.gr/epiheiriseis/forologikes-ypiresies/mitroo/allagi-antistoihisi-kad"
            size="large"
          >
            <Help color="action" />
          </IconButton>
        </Box>
        )}
      </Box>
      {phone && (
      <Typography
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: 20,
          marginLeft: '9px',
        }}
      >
        {t('Φίλτρο Ημ/νιών Σύστασης/Κλεισίματος Επιχείρησης και Μεταβολών')}
      </Typography>
      )}

      <Box
        width={phone ? '98.2%' : '95%'}
        border="solid 1px #cfcfcf"
        justifyContent="space-around"
        display="flex"
        flexWrap="wrap"
        marginLeft="9px"
        marginRight="-9px"
        marginTop={!phone ? '10px' : ''}
      >
        <Box flexDirection="column" display="flex">
          <Typography variant="subtitle1" style={{ marginTop: 10 }}>
            {t('Ημ/νία Σύστασης')}
          </Typography>
          <FormControl size="small">
            <CalendarLocale
              value={dateFrom.recommendationDateFrom || convert(data.recommendationDateFrom)}
              context={data}
              setContext={setData}
              name="recommendationDateFrom"
              maxDate={dateTo.recommendationDateTo}
              label={t('Από')}
              handleDateChange={handleDateChangeFrom}
            />
          </FormControl>

          <FormControl size="small">
            <CalendarLocale
              value={dateTo.recommendationDateTo || convert(data.recommendationDateTo)}
              context={data}
              setContext={setData}
              name="recommendationDateTo"
              minDate={dateFrom.recommendationDateFrom}
              label={t('Έως')}
              handleDateChange={handleDateChangeTo}
            />
          </FormControl>
        </Box>

        <Box flexDirection="column" display="flex">
          <Typography variant="subtitle1" style={{ marginTop: 10 }}>
            {t('Ημ/νία Κλεισίματος')}
          </Typography>
          <FormControl size="small" />
          <CalendarLocale
            label={t('Από')}
            value={dateFrom.closingDateFrom || convert(data.closingDateFrom)}
            context={data}
            maxDate={dateTo.closingDateTo}
            setContext={setData}
            name="closingDateFrom"
            handleDateChange={handleDateChangeFrom}
          />
          <FormControl size="small">
            <CalendarLocale
              label={t('Έως')}
              value={dateTo.closingDateTo || convert(data.closingDateTo)}
              context={data}
              minDate={dateFrom.closingDateFrom}
              setContext={setData}
              name="closingDateTo"
              handleDateChange={handleDateChangeTo}
            />
          </FormControl>
        </Box>

        <Box flexDirection="column" display="flex">
          <Typography variant="subtitle1" style={{ marginTop: 10 }}>
            {t('Ημ/νία Μεταβολής ΚΑΚ')}
          </Typography>
          <FormControl size="small">
            <CalendarLocale
              value={dateFrom.alterationDateFrom || convert(data.alterationDateFrom)}
              context={data}
              maxDate={dateTo.alterationDateTo}
              setContext={setData}
              name="alterationDateFrom"
              label={t('Από')}
              handleDateChange={handleDateChangeFrom}
            />
          </FormControl>

          <FormControl size="small">
            <CalendarLocale
              value={dateTo.alterationDateTo || convert(data.alterationDateTo)}
              context={data}
              setContext={setData}
              minDate={dateFrom.alterationDateFrom}
              name="alterationDateTo"
              label={t('Έως')}
              handleDateChange={handleDateChangeTo}
            />
          </FormControl>
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyItems="center"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container>
          <Box
            display="flex"
            width="100%"
            justifyContent="left"
            marginBottom={!phone ? '5px' : '10px'}
            marginTop={!phone ? '15px' : '10px'}
            marginLeft="9px"
          >
            <Places
              data={data.placesData}
              context={data}
              clear={clear}
              setClear={setClear}
              setContext={setData}
              shouldPlaceUpdate={shouldPlaceUpdate}
              setShouldPlaceUpdate={setShouldPlaceUpdate}
              languageFlag={languageFlag}
              setLanguageFlag={setLanguageFlag}
              name="municipalities"
              placeholder={t('Περιοχή')}
            />
          </Box>
          <Box
            display="flex"
            width="100%"
            flexWrap={phone ? 'nowrap' : 'wrap'}
            justifyContent="space-around"
            marginBottom="10px"
            marginLeft="9px"
          >
            <TextField
              id="outlined-basic"
              style={{ paddingRight: '8px', marginTop: '8px', marginBottom: !phone ? '10px' : '' }}
              fullWidth
              value={data.city ?? ''}
              label={t('Πόλη')}
              variant="outlined"
              onChange={(event) => {
                setData({ ...data, city: event.target.value });
              }}
            />
            <TextField
              id="outlined-basic2"
              fullWidth
              value={data.postcode ?? ''}
              label={t('ΤΚ')}
              style={{ paddingRight: '8px', marginTop: '8px' }}
              error={TKerror}
              helperText={errorText}
              variant="outlined"
              onChange={(event) => {
                if (
                  !event.target.value.match(/\d{5}/g)
                  && event.target.value.length !== 0
                ) {
                  setErrorText(`${t('Ο TK πρέπει να είναι 5 ψηφία')}`);
                  setTKerror(true);
                } else {
                  setErrorText('');
                  setTKerror(false);
                }
                setData({ ...data, postcode: event.target.value });
              }}
            />
          </Box>
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            marginBottom="10px"
            marginTop="10px"
            marginLeft="9px"
          >
            <Button
              style={{ textTransform: 'none' }}
              variant="contained"
              size="large"
              onClick={() => search()}
            >
              {t('Αναζήτηση')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}
