import * as React from 'react';
import Button from '@mui/material/Button';

import { WrapperVariantContext } from '@mui/x-date-pickers/internals';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@mui/material';

const CustomActionBar = (props) => {
  const {
    onAccept, onClear, onCancel, onSetToday, actions,
  } = props;
  const wrapperVariant = React.useContext(WrapperVariantContext);
  const { t } = useTranslation();
  const actionsArray = typeof actions === 'function' ? actions(wrapperVariant) : actions;

  if (actionsArray == null || actionsArray.length === 0) {
    return null;
  }

  const buttonItems = actionsArray?.map((actionType) => {
    switch (actionType) {
      case 'clear':
        return (
          <Button
            data-mui-test="clear-action-button"
            onClick={() => {
              onClear();
            }}
            key={actionType}
          >
            {t('Καθαρισμός')}
          </Button>
        );

      case 'cancel':
        return (
          <Button
            onClick={() => {
              onCancel();
            }}
            key={actionType}
          >
            {t('Ακύρωση')}
          </Button>
        );

      case 'accept':
        return (
          <Button
            onClick={() => {
              onAccept();
            }}
            key={actionType}
          >
            {t('Επιβεβαίωση')}
          </Button>
        );

      case 'today':
        return (
          <Button
            data-mui-test="today-action-button"
            onClick={() => {
              onSetToday();
            }}
            key={actionType}
          >
            {t('Σήμερα')}
          </Button>
        );

      default:
        return null;
    }
  });
  return (
    <ButtonGroup
      sx={{
        marginBottom: 0.5,
        display: 'flex',
        justifyContent: 'center',
      }}
      variant="text"
      aria-label="text button group"
    >
      {buttonItems}
    </ButtonGroup>
  );
};

CustomActionBar.propTypes = {
  /**
   * Ordered array of actions to display.
   * If empty, does not display that action bar.
   * @default `['cancel', 'accept']` for mobile and `[]` for desktop
   */
  // eslint-disable-next-line react/require-default-props
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(['accept', 'cancel', 'clear', 'today'])),
    PropTypes.func,
  ]),
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSetToday: PropTypes.func.isRequired,
};

export default CustomActionBar;
