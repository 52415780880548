import React, { useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { MyContext } from '../../pages/_app';
import CustomActionBar from './CustomActionBar';
import DatepickerLanguageSelect from '../services/DatepickerLanguageSelect';

export default function CalendarLocale({
  context,
  setContext,
  name,
  label,
  handleDateChange,
  value,
  maxDate,
  minDate,
}) {
  const language = useContext(MyContext);
  const format = language.language !== 'en' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  const locale = DatepickerLanguageSelect(language);
  const today = new Date();
  const maximumDate = maxDate ?? today;
  const minimumDate = minDate ?? 0;
  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat={format}
        value={value}
        margin="normal"
        maxDate={maximumDate}
        minDate={minimumDate}
        label={label}
        components={{
          ActionBar: CustomActionBar,
        }}
        componentsProps={{
          actionBar: {
            actions: ['clear', 'today', 'cancel', 'accept'],
          },
        }}
        onChange={(date) => {
          const formattedDate = moment(date).isValid() ? moment(date, 'MM/DD/yyyy').format(
            'DD/MM/yyyy',
          ) : null;
          handleDateChange(date, name);
          setContext({ ...context, [name]: formattedDate });
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        renderInput={(params) => <TextField variant="standard" {...params} />}
      />
    </LocalizationProvider>
  );
}
