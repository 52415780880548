/* eslint-disable  */
import DropdownTreeSelect from 'react-dropdown-tree-select';
import React, { Component, useEffect, useMemo, useState } from 'react';
import DropDownTreeSelectContainer from './DropDownTreeSelectContainer';

import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

export default class Places extends Component {
  constructor(props) {
    super(props);
    this.triggerReRender = this.triggerReRender.bind(this);
    this.places = []
  }
  onChange (selectedNodes)  {
    this.props.setContext({
      ...this.props.context,
      places: selectedNodes.checked
        ? [...this.props.context.places, selectedNodes]
        : [...this.props.context.places].filter(
          (selected) => selected.checked === true,
        ),
    });
  };

  triggerReRender() {
    this.props.setShouldPlaceUpdate(true);
    setTimeout(() => {
      this.places = this.props.data
      this.props.setShouldPlaceUpdate(false);
    }, 200);
  }

  render() {
    return (
      <div className="App" style={{position: 'relative'}}>
        <DropDownTreeSelectContainer
          className="mdl-demo"
          clear={this.props.clear}
          context={this.props.context}
          data={this.places}
          keepTreeOnSearch
          languageFlag={this.props.languageFlag}
          mode="Places"
          onChange={this.onChange.bind(this)}
          setClear={this.props.setClear}
          setContext={this.props.setContext}
          setLanguageFlag={this.props.setLanguageFlag}
          texts={{ placeholder: this.props.placeholder }}
          triggerReRender={this.triggerReRender.bind(this)}
        />
        {this.props.shouldPlaceUpdate && this.places.length === 0 ? (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '12em',
              left: '22em',
              zIndex: '22',
            }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
