/* eslint-disable */
import React, { Component } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import isEqual from 'lodash/isEqual';
import { Clear } from '@mui/icons-material';
import { IconButton, Box } from '@mui/material';
import 'react-dropdown-tree-select/dist/styles.css';

export default class DropDownTreeSelectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      changed: false,
      context: this.props.context,
      setContext: this.props.setContext,
      hasSearched: false,
    });
  }

  componentWillReceiveProps(nextProps){
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  };

  shouldComponentUpdate(nextProps){
    if (!isEqual(nextProps.data, this.state.data)) {
      return true;
    } else if (this.props.languageFlag) {
      this.props.setLanguageFlag(false);
      return true;
    } else if (this.state.changed) {
      this.state.changed = false;
      return true;
    } else if (nextProps.clear) {
      nextProps.setClear(false);
      return true;
    } else if (nextProps.data !== this.state.data) {
      return true;
    } else {
      return false;
    }
  };
  searchPredicate (node, searchTerm) {
    return node.label && node.label.toLowerCase().indexOf(searchTerm) >= 0;
  };

  handleClear(){
    const data = this.state.data;
    data[0].checked = false;
    this.setState({ data });
    this.state.changed = true;
    if (this.props.mode === 'kad') {
      this.state.setContext({ ...this.props.context, kad: [] });
    } else if (this.props.mode === 'Places') {
      this.state.setContext({ ...this.props.context, places: [] });
    }
  };

  render() {
    const { data, ...rest } = this.props;
    return (
      <Box display="flex" flexWrap="nowrap" justifyContent="flex-start">
        <DropdownTreeSelect
          data={data}
          searchPredicate={this.searchPredicate.bind(this)}
          {...rest}
          onFocus={this.props.triggerReRender}
          id={this.props.mode}
        />
        <IconButton
          style={{ background: 'transparent' }}
          onClick={this.handleClear.bind(this)}
          size="large">
          <Clear />
        </IconButton>
      </Box>
    );
  }
}
