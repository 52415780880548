/* eslint-disable */
import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  FormControl,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  Button,
  Chip,
} from '@mui/material';
import { MultiSelectAutocomplete } from './MultiSelectAutocomplete';
import { filterOptions } from '../../pages/index';
import { useTranslation } from 'react-i18next';
import { MyContext } from '../../pages/_app';
import CustomActionBar from './CustomActionBar';
import TextField from '@mui/material/TextField';
import DatepickerLanguageSelect from '../services/DatepickerLanguageSelect';
const PREFIX = 'PersonFiltersGroup';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.input}`]: {
    height: '40px',
    width: '245px',
    margin: 3,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  }
}));

export default function PersonFiltersGroup({ value }) {
  const phone = useMediaQuery('(min-width:600px)');
  const { t } = useTranslation();
  const context = useContext(filterOptions);
  const filter = context.personFiltersGroup;
  const { selectedFilterStringPerson } = value ?? [];
  const names = ['Value1', 'Value2', 'Value3'];
  const personName = ['Value1', 'Value2', 'Value3'];
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const { data, setData, newLangLoading } = context;
  const today = new Date();
  const maximumDate = dateTo ?? today;
  const minimumDate = dateFrom ?? 0;
  const language = useContext(MyContext);
  const format = language.language !== 'en' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  const locale = DatepickerLanguageSelect(language);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleDateChangeFrom = (date) => {
    setDateFrom(date);
  };
  const handleDateChangeTo = (date) => {
    setDateTo(date);
  };
  function clearCompanyFilters() {
    setData({
      ...data,
      person: [],
      personrecommendationDateFrom: null,
      personrecommendationDateTo: null,
    });
    setDateFrom(null);
    setDateTo(null);
  }

  return (
    <StyledBox display="flex" flexDirection="column" width="100%">
      <Typography style={{display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', gap: '5px'}}>
        {selectedFilterStringPerson?.length !== 0 ? (
          <>
            {t('Προστέθηκε φίλτρο')}:{' '}
            {selectedFilterStringPerson?.map((item) => (
              <Chip label={item} />
            ))}
          </>
        ) : null}
      </Typography>
      <Button
        onClick={clearCompanyFilters}
        style={{ alignSelf: 'flex-end' }}
      >
        {t('Καθαρισμος')}
      </Button>
      <Box
        display="flex"
        width="100%"
        flexWrap={phone ? 'nowrap' : 'wrap'}
        justifyContent="center"
        marginBottom="10px"
      >
        <MultiSelectAutocomplete
          context={data}
          setContext={setData}
          fullWidth
          name="person"
          options={
            newLangLoading
              ? []
              : data.personData.filter(
                  (item) => !item.descr.includes('no info'),
                )
          }
          multiple={true}
          label={t('Συσχέτιση με επιχείρηση')}
          loading={newLangLoading}
        />
      </Box>

      <Typography style={{ display: 'flex', flexWrap: 'wrap', marginTop: 20 }}>
        {t('Φίλτρο Ημ/νιών Συσχέτισης Φυσικού Προσώπου με Επιχείρηση')} :
      </Typography>

      <Box
        display="flex"
        flexWrap={phone ? 'nowrap' : 'wrap'}
        border="1px solid #cfcfcf"
        style={{ maxWidth: '100%', paddingLeft: '10%' }}
      >
        <Typography variant="subtitle1" style={{ marginTop: 10 }}>
          {t('Ημ/νία Συσχέτισης')}
        </Typography>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl size="small">
              <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
                <DatePicker
                  margin="normal"
                  label={t('Από')}
                  clearable
                  inputFormat={format}
                  maxDate={maximumDate}
                  components={{
                    ActionBar: CustomActionBar,
                  }}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear', 'today', 'cancel', 'accept'],
                    },
                  }}
                  onChange={(date, value) => {
                    handleDateChangeFrom(date);
                    setData({
                      ...data,
                      ['personrecommendationDateFrom']: value,
                    });
                  }}
                  value={dateFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="small">
              <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
                <DatePicker
                  margin="normal"
                  label={t('Έως')}
                  inputFormat={format}
                  clearable
                  maxDate={today}
                  minDate={minimumDate}
                  components={{
                    ActionBar: CustomActionBar,
                  }}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear', 'today', 'cancel', 'accept'],
                    },
                  }}
                  onChange={(date, value) => {
                    handleDateChangeTo(date);
                    setData({ ...data, ['personrecommendationDateTo']: value });
                  }}
                  value={dateTo}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
  );
}
