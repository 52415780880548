/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CompanyFilter from './CompanyFiltersGroup';
import PhysicalPerson from './PersonFiltersGroup';

export default function AllFilters({ data }) {
  const { t } = useTranslation();
  const phone = useMediaQuery('(min-width:600px)');
  const [expanded, setExpanded] = useState(true);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { selectedFilterStringCompany, selectedFilterStringPerson, search } = data;
  return (
    <Box width="100%" flexDirection="column">
      <Accordion
        id="companyFilters"
        onChange={handleChange('expaneded')}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordion-content"
        >
          <Typography>{t('Φίλτρα Επιχείρησης')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CompanyFilter value={{ selectedFilterStringCompany, search }} />
        </AccordionDetails>
      </Accordion>

      {process.env.NEXT_PUBLIC_PERSON_FILTERS === 'true' && (
      <Accordion style={{ marginBottom: '5%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordion-content"
        >
          <Typography>{t('Φίλτρα Φυσικού Προσώπου')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PhysicalPerson value={{ selectedFilterStringPerson }} />
        </AccordionDetails>
      </Accordion>
      )}
    </Box>
  );
}
