import elGR from 'date-fns/locale/el';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';

const DatepickerLanguageSelect = (language) => {
  let locale;
  switch (language.language) {
    case 'el':
      locale = elGR;
      break;
    case 'en':
      locale = enUS;
      break;
    case 'fr':
      locale = fr;
      break;
    case 'ger':
      locale = de;
      break;
    case 'it':
      locale = it;
      break;
    default:
      locale = enUS;
      break;
  }
  return locale;
};
export default DatepickerLanguageSelect;
