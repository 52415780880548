/* eslint-disable */
import DropdownTreeSelect from 'react-dropdown-tree-select';
import React, { Component, useEffect, useMemo, useState } from 'react';
import DropDownTreeSelectContainer from './DropDownTreeSelectContainer';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

export default class Kad extends Component {
  constructor(props) {
    super(props);
    let hasChangedLang;
    this.state = { hasSearched: false, data: [], hasChangedLang };
    this.triggerReRender = this.triggerReRender.bind(this);
  }
  async getKadData() {
    if (this.state.hasSearched === true) return;
    else {
      this.props.setShouldKadUpdate(true);
      const response = await axios.post('/api/getKadOptions', {
        language: this.props.currentLanguage,
      });
      this.props.setShouldKadUpdate(false);
      this.setState({ data: response.data, hasSearched: true });
    }
  };
  triggerReRender() {
    this.getKadData();
  }
  onChange(selectedNodes){
    this.props.setContext({
      ...this.props.context,
      kad: selectedNodes.checked
        ? [...this.props.context.kad, selectedNodes.value]
        : [...this.props.context.kad].filter(function (e) {
            return e !== selectedNodes.value;
          }),
    });
  };
  render() {
    return (
      <div className="App" style={{position: 'relative'}}>
        {this.props.languageFlag
          ? this.setState({ ...this.state, hasChangedLang: true })
          : null}
        <DropDownTreeSelectContainer
          className="mdl-demo"
          clear={this.props.clear}
          context={this.props.context}
          data={this.state.hasChangedLang ? this.props.data : this.state.data}
          keepTreeOnSearch
          languageFlag={this.props.languageFlag}
          mode="kad"
          onChange={this.onChange.bind(this)}
          setClear={this.props.setClear}
          setContext={this.props.setContext}
          setLanguageFlag={this.props.setLanguageFlag}
          texts={{ placeholder: this.props.placeholder }}
          triggerReRender={this.triggerReRender.bind(this)}
        />
        {this.props.shouldKadUpdate ? (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '12em',
              left: '22em',
              zIndex: '22',
            }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

Kad.propTypes = {
  data: PropTypes.array,
};
