/* eslint-disable import/prefer-default-export */
/* eslint-disable react/require-default-props */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export const MultiSelectAutocomplete = ({
  options,
  multiple,
  label,
  onInputChange,
  context,
  setContext,
  name,
  fullWidth,
  disabled,
  loading,
}) => (
  <Autocomplete
    options={options}
    value={
      loading ? [] : options?.filter((item) => context[name].includes(item.id))
    }
    disabled={disabled}
    loading={loading}
    loadingText={(
      <Box
        display="flex"
        p="1em"
        justifyContent="center"
        justifyItems="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    )}
    multiple={multiple}
    fullWidth={fullWidth}
    disableCloseOnSelect
    style={{ padding: '8px' }}
    onInputChange={onInputChange}
    onChange={(_, data) => {
      const autocompleteData = [];
      for (let i = 0; i < data?.length; i++) {
        autocompleteData.push(data[i].id);
      }
      setContext({ ...context, [name]: autocompleteData });
    }}
    getOptionLabel={(option) => option.descr}
    renderInput={(params) => (
      <TextField {...params} label={label} variant="outlined" />
    )}
  />
);
